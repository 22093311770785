import Axios from 'axios'
import { Context } from '@nuxt/types'
import { BasicService } from '~/services/BasicService'
import { reverseApi } from '~~/config'
import { onFulfilled, onRejected } from '~/services/interceptors'
import { validateParams } from '~/modules/tools'

export interface IReverseInfoParams {
  coinType: string,
  address: string,
}

export interface IReverseInfoRes {
  account: string,
  is_valid: boolean,
  reverse_status: number,
}

export default class DasReverse extends BasicService {
  constructor (context: Context) {
    super(context)
    this.axios = Axios.create({
      baseURL: reverseApi,
      withCredentials: true
    })
    this.axios.interceptors.response.use(onFulfilled(context), onRejected())
  }

  reverseInfo ({
    coinType,
    address
  }: IReverseInfoParams): Promise<IReverseInfoRes> {
    validateParams({ coinType, address }, 'reverseInfo')
    return this.axios.post('/reverse/info', {
      type: 'blockchain',
      key_info: {
        coin_type: coinType,
        key: address
      }
    })
  }
}
