import { Context } from '@nuxt/types'
import Common from '~/services/Common'
import Account from '~/services/Account'
import SubAccount from '~/services/SubAccount'
import DasReverse from './DasReverse'

export class Services {
  common: Common
  account: Account
  subAccount: SubAccount
  dasReverse: DasReverse

  constructor (context: Context) {
    this.common = new Common(context)
    this.account = new Account(context)
    this.subAccount = new SubAccount(context)
    this.dasReverse = new DasReverse(context)
  }
}
