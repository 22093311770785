import BN from 'bn.js'
import Decimal from 'decimal.js'
// @ts-expect-error
import abcCopy from 'abc-copy'
import { SignTypedDataVersion, TypedMessage, TypedDataUtils, MessageTypes } from '@metamask/eth-sig-util'
import dayjs, { Dayjs } from 'dayjs'
import {
  ACCOUNT_SUFFIX,
  CHAR_TYPE,
  DigitalEmojiUnifiedMap,
  DWEB_KEY_OPTIONS,
  PROFILE_KEY_OPTIONS,
  TIME_FORMAT,
  TOKEN_DECIMAL_PLACES
} from '~/constant'
import { ChainType, CKB, ICoinTypeInfo, PARSING_RECORD_SUPPORT_CHAINS } from '~/constant/chain'
import GraphemeSplitter from 'grapheme-splitter'
import Das from 'das-sdk'
import { Buffer } from 'buffer'
// @ts-expect-error
import blake2b from 'blake2b'
// @ts-expect-error
import emojiList from '~/modules/char_set/emoji_list.json'
// @ts-expect-error
import numberList from '~/modules/char_set/digit_list.json'
// @ts-expect-error
import englishList from '~/modules/char_set/en_list.json'
// @ts-expect-error
import turkishList from '~/modules/char_set/tr_list.json'
// @ts-expect-error
import thaiList from '~/modules/char_set/th_list.json'
// @ts-expect-error
import koreanList from '~/modules/char_set/ko_list.json'
// @ts-expect-error
import vietnameseList from '~/modules/char_set/vi_list.json'
import isValidDomain from 'is-valid-domain'
import { validate as bitcoinAddressValidation } from 'bitcoin-address-validation'
import { ckbNode } from '~~/config'

/**
 * Used to determine whether it is a mobile terminal
 */
export function isMobile (): boolean {
  return window.innerWidth < 960
}

/**
 * Enhance the key of the module in vuex, because these modules have namespace, so the outside world must add namespace if they want to use it.
 * @param keys
 * @param namespace
 */
export function augmentKeys<T> (keys: T, namespace: string): T {
  const ret: any = {}
  for (const key in keys) {
    if (!Object.prototype.hasOwnProperty.call(keys, key)) {
      continue
    }

    if (key === 'namespace') {
      ret[key] = keys[key]
    }
    else {
      ret[key] = `${namespace}/${keys[key]}`
    }
  }
  return ret
}

/**
 * shrinkUnit
 * @param value
 * @param shrinkDecimals
 * @param precision
 */
export function shrinkUnit (value: string|number|Decimal, shrinkDecimals: number, precision = 8): string {
  value = value || 0
  const decimalNum = Decimal.div(value, 10 ** shrinkDecimals)
  let decimals = decimalNum.decimalPlaces()

  if (decimals > precision) {
    decimals = precision
  }
  return decimalNum.toFixed(decimals, Decimal.ROUND_DOWN)
}

export function fromSatoshi (satoshi: string): string {
  return shrinkUnit(satoshi, CKB.decimals, TOKEN_DECIMAL_PLACES)
}

/**
 * expandUnit
 * @param value
 * @param decimals
 * @param precision
 */
export function expandUnit (value: string|number|Decimal, decimals: number, precision = 0): string {
  value = value || 0
  return Decimal.mul(value, 10 ** decimals).toFixed(precision, Decimal.ROUND_DOWN)
}

/**
 * Thousands of divisions
 * @param num
 * @param digits
 */
export function thousandSplit (num: number | string | Decimal, digits?: number): string {
  if (digits && digits > 0) {
    if (Decimal.isDecimal(num)) {
      num = (num as Decimal).toFixed(digits).toString()
    }
    else {
      num = new Decimal(num).toFixed(digits).toString()
    }
  }
  const strNum = num + ''
  if (strNum.includes('.')) {
    return (num + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
  }
  else {
    return strNum.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  }
}

/**
 * Copy data to the clipboard
 * @param text
 * @param el
 */
export function copyText (text: string, el?: Element): Promise<void> {
  return abcCopy(text, {
    target: el
  })
}

/**
 * String reduction
 * @param inputString
 * @param head
 * @param tail
 * @param tokenStr
 */
export function collapseString (inputString = '', head = 4, tail = 4, tokenStr = '...'): string {
  const splitter = new GraphemeSplitter()
  const split = splitter.splitGraphemes(inputString)
  if (split.length > 12) {
    return split.slice(0, head).join('') + tokenStr + split.slice(split.length - tail, split.length).join('')
  }
  else {
    return inputString
  }
}

/**
 * Handling dayjs format not supported in safari 2019-07-02T13:34:11+0000
 * @param timestamp
 */
export function safariTimestampFormat (timestamp: string | number | Dayjs): string | number | Dayjs {
  if (typeof timestamp === 'string') {
    return timestamp.replace(/\+0000/, 'Z')
  }
  else {
    return timestamp
  }
}

/**
 * Convert the time to the specified time format
 * @param timestamp
 * @param template
 */
export function formatDateTime (timestamp: string | number | Dayjs, template = TIME_FORMAT.fullDateTime): string {
  timestamp = safariTimestampFormat(timestamp)
  return dayjs(timestamp).format(template)
}

/**
 * load script
 * @param src
 * @param id
 */
export function loadScript (src: string, id: string): Promise<any> {
  const script = 'script'
  const firstScript: HTMLScriptElement = document.getElementsByTagName(script)[0]
  if (document.getElementById(id)) {
    return Promise.resolve()
  }
  const scriptElement: HTMLScriptElement = document.createElement(script)
  scriptElement.id = id
  scriptElement.src = src
  firstScript.parentNode?.insertBefore(scriptElement, firstScript)

  return new Promise((resolve, reject) => {
    scriptElement.onload = resolve
    scriptElement.onerror = reject
  })
}

/**
 * get all user agent
 */
export function getAllUserAgent () {
  const ua: string = window.navigator.userAgent.toLowerCase()

  const wechat = ua.includes('micromessenger')
  const android = ua.includes('android')
  const ios = ua.includes('iphone')
  const ipados = ua.includes('ipad')

  return {
    // app user agent
    wechat,
    weibo: ua.includes('weibo'),
    abcwallet: ua.includes('abcwallet'),
    mathwallet: ua.includes('mdsapp'),
    tokenpocket: ua.includes('tokenpocket'),
    bitpie: ua.includes('bitpie'),
    // platform user agent
    android,
    ios,
    ipados,
    mobile: android || ios,
    windows: ua.includes('windows'),
    ubuntu: ua.includes('ubuntu'),
    mac: ua.includes('mac'),
    messenger: !wechat && (
      ua.includes('messenger') ||
      ua.includes('fbav') ||
      ua.includes('fban') ||
      window.location.href.includes('fb_iframe_origin')
    )
  }
}

/**
 * detects if the given user agent is supported
 * @param uaList
 */
export function isUASupported (uaList: string[]): boolean {
  const uaMap = getAllUserAgent()
  return uaList.some(ua => (uaMap as any)[ua])
}

/**
 * detect if the visitor is coming from mobile
 */
export function isMobileDevices (): boolean {
  return isUASupported(['wechat', 'android', 'ios'])
}

/**
 * check if the current access environment is a SafePal wallet environment
 */
export function isSafePalWallet (): boolean {
  const { ethereum } = window
  return !!(ethereum?.isSafePal)
}

/**
 * check if the current access environment is a Bitpie wallet environment
 */
export function isBitpieWallet (): boolean {
  return isUASupported(['bitpie'])
}

/**
 * check if the current access environment is a TokenPocket environment
 */
export function isTokenPocket (): boolean {
  return isUASupported(['tokenpocket'])
}

/**
 * check if the current access environment is a ViaWallet wallet environment
 */
export function isViaWallet (): boolean {
  const { ethereum } = window
  if (typeof ethereum !== 'undefined') {
    return !!ethereum.isViaWallet
  }
  else {
    return false
  }
}

/**
 * check if the current access environment is a Coinbase Wallet environment
 */
export function isCoinbaseWallet (): boolean {
  const { ethereum } = window
  if (typeof ethereum !== 'undefined') {
    return !!ethereum.isCoinbaseWallet
  }
  else {
    return false
  }
}

/**
 * string initial capitalization
 * @param value
 */
export function capitalize (value: string): string {
  if (value) {
    return value.replace(/^\S/, s => s.toUpperCase())
  }
  else {
    return ''
  }
}

/**
 * 0 is added to the front of a number less than 10
 * @param num
 */
export function replenishZero (num: number): string {
  if (Number(num) < 10) {
    return `0${num}`
  }
  return `${num}`
}

export interface IDateObject {
  hours: number | string,
  minutes: number | string,
  seconds: number | string,
  days: number | string,
}

/**
 * get the countdown time object
 * @param timestamp
 */
export function getTimeRemaining (timestamp: number): IDateObject {
  const seconds = Math.floor((timestamp / 1000) % 60)
  const minutes = Math.floor((timestamp / 1000 / 60) % 60)
  const hours = Math.floor((timestamp / (1000 * 60 * 60)) % 24)
  const days = Math.floor(timestamp / (1000 * 60 * 60 * 24))
  return {
    hours: replenishZero(hours),
    minutes: replenishZero(minutes),
    seconds: replenishZero(seconds),
    days
  }
}

/**
 * get month and day date
 */
export function getMonthAndDate (timestamp: number): string {
  const date = dayjs(timestamp)
  return `${replenishZero(date.month() + 1)}/${replenishZero(date.date())}`
}

/**
 * sleep for a fixed time
 * @param ms
 */
export function sleep (ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms))
}

/**
 * get mmJson hash and chainId hex
 * @param typedData
 * @param chainId
 */
export function mmJsonHashAndChainIdHex (typedData: TypedMessage<MessageTypes>, chainId: number): string {
  const mmHash = TypedDataUtils.eip712Hash(typedData, SignTypedDataVersion.V4).toString('hex')
  const chainIdHex = new BN(chainId).toString(16, 16)
  return mmHash + chainIdHex
}

export interface ICharInfo {
  char_set_name: number,
  char: string,
}

export function splitAccount (account: string, addSuffix = false): ICharInfo[] {
  const splitter = new GraphemeSplitter()
  let split = splitter.splitGraphemes(account)

  split = split.map((item) => {
    // @ts-expect-error
    if (DigitalEmojiUnifiedMap[item]) {
      // @ts-expect-error
      return DigitalEmojiUnifiedMap[item]
    }
    else {
      return item
    }
  })

  const englishSplitArr: ICharInfo[] = split.map((char: string) => {
    let _charType: number = CHAR_TYPE.unknown
    if (emojiList.includes(char)) {
      _charType = CHAR_TYPE.emoji
    }
    else if (numberList.includes(char)) {
      _charType = CHAR_TYPE.number
    }
    else if (englishList.includes(char)) {
      _charType = CHAR_TYPE.english
    }

    return {
      char_set_name: _charType,
      char
    }
  })

  const turkishSplitArr: ICharInfo[] = split.map((char: string) => {
    let _charType: number = CHAR_TYPE.unknown
    if (emojiList.includes(char)) {
      _charType = CHAR_TYPE.emoji
    }
    else if (numberList.includes(char)) {
      _charType = CHAR_TYPE.number
    }
    else if (turkishList.includes(char)) {
      _charType = CHAR_TYPE.turkish
    }

    return {
      char_set_name: _charType,
      char
    }
  })

  const vietnameseSplitArr: ICharInfo[] = split.map((char: string) => {
    let _charType: number = CHAR_TYPE.unknown
    if (emojiList.includes(char)) {
      _charType = CHAR_TYPE.emoji
    }
    else if (numberList.includes(char)) {
      _charType = CHAR_TYPE.number
    }
    else if (vietnameseList.includes(char)) {
      _charType = CHAR_TYPE.vietnamese
    }

    return {
      char_set_name: _charType,
      char
    }
  })

  const thaiSplitArr: ICharInfo[] = split.map((char: string) => {
    let _charType: number = CHAR_TYPE.unknown
    if (emojiList.includes(char)) {
      _charType = CHAR_TYPE.emoji
    }
    else if (numberList.includes(char)) {
      _charType = CHAR_TYPE.number
    }
    else if (thaiList.includes(char)) {
      _charType = CHAR_TYPE.thai
    }

    return {
      char_set_name: _charType,
      char
    }
  })

  const koreanSplitArr: ICharInfo[] = split.map((char: string) => {
    let _charType: number = CHAR_TYPE.unknown
    if (emojiList.includes(char)) {
      _charType = CHAR_TYPE.emoji
    }
    else if (numberList.includes(char)) {
      _charType = CHAR_TYPE.number
    }
    else if (koreanList.includes(char)) {
      _charType = CHAR_TYPE.korean
    }

    return {
      char_set_name: _charType,
      char
    }
  })

  const englishUnknownChar = englishSplitArr.find((item: ICharInfo) => {
    return item.char_set_name === CHAR_TYPE.unknown
  })
  const turkishUnknownChar = turkishSplitArr.find((item: ICharInfo) => {
    return item.char_set_name === CHAR_TYPE.unknown
  })
  const vietnameseUnknownChar = vietnameseSplitArr.find((item: ICharInfo) => {
    return item.char_set_name === CHAR_TYPE.unknown
  })
  const thaiUnknownChar = thaiSplitArr.find((item: ICharInfo) => {
    return item.char_set_name === CHAR_TYPE.unknown
  })
  const koreanUnknownChar = koreanSplitArr.find((item: ICharInfo) => {
    return item.char_set_name === CHAR_TYPE.unknown
  })

  let splitArr = null

  if (!englishUnknownChar) {
    splitArr = englishSplitArr
  }
  else if (!turkishUnknownChar) {
    splitArr = turkishSplitArr
  }
  else if (!vietnameseUnknownChar) {
    splitArr = vietnameseSplitArr
  }
  else if (!thaiUnknownChar) {
    splitArr = thaiSplitArr
  }
  else if (!koreanUnknownChar) {
    splitArr = koreanSplitArr
  }
  else {
    splitArr = split.map((char: string) => {
      return {
        char_set_name: CHAR_TYPE.unknown,
        char
      }
    })
  }

  const unknownChar = splitArr.find((item: ICharInfo) => {
    return item.char_set_name === CHAR_TYPE.unknown
  })

  if (!unknownChar) {
    const charList: { [key: string]: any } = {}
    if (!englishUnknownChar) {
      charList.en = englishSplitArr
    }

    if (!turkishUnknownChar) {
      charList.tr = turkishSplitArr
    }

    if (!vietnameseUnknownChar) {
      charList.vi = vietnameseSplitArr
    }

    if (!thaiUnknownChar) {
      charList.th = thaiSplitArr
    }

    if (!koreanUnknownChar) {
      charList.ko = koreanSplitArr
    }

    let language = window.navigator.language
    if (/^en/i.test(language)) {
      language = 'en'
    }
    else if (/^ja/i.test(language)) {
      language = 'ja'
    }
    else if (/^ru/i.test(language)) {
      language = 'ru'
    }
    else if (/^tr/i.test(language)) {
      language = 'tr'
    }
    else if (/^vi/i.test(language)) {
      language = 'vi'
    }
    else if (/^th/i.test(language)) {
      language = 'th'
    }
    else if (/^ko/i.test(language)) {
      language = 'ko'
    }

    if (charList[language]) {
      splitArr = charList[language]
    }
    else if (charList.en) {
      splitArr = charList.en
    }
  }

  if (addSuffix) {
    ACCOUNT_SUFFIX.split('')
      .forEach((char: string) => {
        splitArr.push({
          char_set_name: CHAR_TYPE.english,
          char
        })
      })
  }

  return splitArr
}

/**
 * Find the corresponding key-value pair by parsing the value of the record
 * @param value
 */
export function findParsingRecordChain (value: string): ICoinTypeInfo {
  const chain = PARSING_RECORD_SUPPORT_CHAINS.find((item) => {
    return item.value === value || item.coinType === value
  })
  if (chain) {
    return chain
  }
  else {
    return {
      text: value.toUpperCase(),
      value,
      coinType: ''
    }
  }
}

/**
 * Find the corresponding key-value pair by profile value
 * @param value
 */
export function findProfile (value: string): { text: string, value: string } {
  const profile = PROFILE_KEY_OPTIONS.find((item) => {
    return item.value === value
  })
  if (profile) {
    return profile
  }
  else {
    return {
      text: value.toUpperCase(),
      value
    }
  }
}

/**
 * Find the corresponding key-value pair by Dweb value
 * @param value
 */
export function findDweb (value: string): { text: string, value: string } {
  const dweb = DWEB_KEY_OPTIONS.find((item) => {
    return item.value === value
  })
  if (dweb) {
    return dweb
  }
  else {
    return {
      text: value.toUpperCase(),
      value
    }
  }
}

export function toDottedStyle (inputAccount: string): string {
  return Das.toDottedStyle(inputAccount)
}

export function toHashedStyle (inputAccount: string): string {
  // return Das.toHashedStyle(inputAccount)
  return inputAccount
}

export function nftTokenId (account: string): string {
  const personal = Buffer.from('ckb-default-hash')
  const accountBuf = Buffer.from(account)
  const hasher = blake2b(32, null, null, personal)
  hasher.update(accountBuf)
  const hashBuffer = hasher.digest('binary') as Uint8Array
  const first20Bytes = Buffer.from(hashBuffer.slice(0, 20))
  const tokenId = new BN(first20Bytes.toString('hex'), 16).toString(10)
  return tokenId
}

export function nftTokenIdHex (account: string): string {
  const personal = Buffer.from('ckb-default-hash')
  const accountBuf = Buffer.from(account)
  const hasher = blake2b(32, null, null, personal)
  hasher.update(accountBuf)
  const hashBuffer = hasher.digest('binary') as Uint8Array
  const first20Bytes = Buffer.from(hashBuffer.slice(0, 20))
  return '0x' + first20Bytes.toString('hex')
}

/**
 * handles the mapping of digital emoji
 * @param str
 */
export function digitalEmojiUnifiedHandle (str: string): string {
  const splitter = new GraphemeSplitter()
  const split = splitter.splitGraphemes(str)
  const list = split.map((item) => {
    // @ts-expect-error
    if (DigitalEmojiUnifiedMap[item]) {
      // @ts-expect-error
      return DigitalEmojiUnifiedMap[item]
    }
    else {
      return item
    }
  })
  return list.join('')
}

/**
 * Determine if the domain host is correct
 * @param host
 */
export function isDomainHost (host: string): boolean {
  try {
    return isValidDomain(host, { subdomain: true, wildcard: false, allowUnicode: true, topLevel: false })
  }
  catch (err) {
    console.error(err)
    return false
  }
}

/*
 *  Convert signature data from tp-utxo to hex
 *  @param {string} base64SignData base64 encoded signature data
 *  @return {string} hex encoded signature data
 */
export function convertTpUTXOSignature (base64SignData: string): string {
  const buffer = Buffer.from(base64SignData, 'base64')
  if (buffer.length !== 65) throw new Error('Invalid signature length')
  const flagByte = buffer.readUInt8(0) - 27
  if (flagByte > 15 || flagByte < 0) {
    throw new Error('Invalid signature parameter')
  }

  const decodeData = {
    compressed: !!(flagByte & 12),
    segwitType: !(flagByte & 8)
      ? null
      : !(flagByte & 4)
        ? 'p2sh(p2wpkh)'
        : 'p2wpkh',
    recovery: flagByte & 3,
    signature: buffer.slice(1)
  }
  const signData = Buffer.concat([decodeData.signature, Buffer.from([decodeData.recovery]), Buffer.from([decodeData.compressed ? 1 : 0])])
  return '0x' + signData.toString('hex')
}

export function isDogecoinChain (chainType: ChainType) {
  return chainType === ChainType.doge
}

export function validateParams (params: { [key: string]: any }, outerFuncName: string) {
  if (typeof params !== 'object' || params === null) {
    throw new Error('validateParams params input is not an object')
  }

  Object.entries(params).forEach(([key, value]) => {
    if (!value) {
      try {
        const _value: string = value.toString()
        throw new Error(`${outerFuncName} params value for property "${key}" is ${_value}`)
      }
      catch (err) {
        console.error(err)
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        throw new Error(`${outerFuncName} params value for property "${key}", ${err.toString()}`)
      }
    }
  })
}

/**
 * open link in different ways depending on the device.
 * @param link
 */
export function smartOpen (link: string) {
  if (isMobile()) {
    window.location.href = link
  }
  else {
    window.open(link)
  }
}

/**
 * determine if it is a bitcoin address
 * @param address
 */
export function isBitcoinAddress (address: string): boolean {
  return bitcoinAddressValidation(address)
}

export function removeDotbitSuffix (account: string): string {
  const pattern = /^(([^\\.\s]+\.){1,})bit$/
  const result = account.match(pattern)
  if (result) {
    return result[1].slice(0, -1)
  }
  return ''
}

/**
 * Transfer CKB using Portal Wallet.
 * @param to
 * @param value
 * @param data
 */
export async function portalWalletSendCKB ({ to, value, data }: {
  to: string,
  value: string,
  data?: string,
}): Promise<string> {
  try {
    const res = await import('~/modules/DasEthProvider')
    const pwcore = await new res.PW(ckbNode).init(
      new res.DasEthProvider(),
      new res.IndexerCollector(ckbNode)
    )

    const hash = await pwcore.send(
      new res.Address(to, res.AddressType.ckb),
      new res.Amount(shrinkUnit(value, CKB.decimals)),
      {
        data,
        witnessArgs: res.Builder.WITNESS_ARGS.RawSecp256k1
      }
    )
    return hash
  }
  catch (err) {
    console.error(err)
    throw err
  }
}

export function appendBaseUrl(url:string){
  return `${document.getElementsByTagName("base")[0].href}${url}`
}