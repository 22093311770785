export const AddressInconsistencyAlert = () => import('../../src/components/AddressInconsistencyAlert.vue' /* webpackChunkName: "components/address-inconsistency-alert" */).then(c => wrapFunctional(c.default || c))
export const BottomSheet = () => import('../../src/components/BottomSheet.vue' /* webpackChunkName: "components/bottom-sheet" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../src/components/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const ContentHeader = () => import('../../src/components/ContentHeader.vue' /* webpackChunkName: "components/content-header" */).then(c => wrapFunctional(c.default || c))
export const DasBalanceInsufficientDialog = () => import('../../src/components/DasBalanceInsufficientDialog.vue' /* webpackChunkName: "components/das-balance-insufficient-dialog" */).then(c => wrapFunctional(c.default || c))
export const Dialog = () => import('../../src/components/Dialog.vue' /* webpackChunkName: "components/dialog" */).then(c => wrapFunctional(c.default || c))
export const Dropdown = () => import('../../src/components/Dropdown.vue' /* webpackChunkName: "components/dropdown" */).then(c => wrapFunctional(c.default || c))
export const FiatPayDialog = () => import('../../src/components/FiatPayDialog.vue' /* webpackChunkName: "components/fiat-pay-dialog" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../src/components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const InsufficientBalanceDialog = () => import('../../src/components/InsufficientBalanceDialog.vue' /* webpackChunkName: "components/insufficient-balance-dialog" */).then(c => wrapFunctional(c.default || c))
export const LangSwitcher = () => import('../../src/components/LangSwitcher.vue' /* webpackChunkName: "components/lang-switcher" */).then(c => wrapFunctional(c.default || c))
export const MultipleWalletPluginsTips = () => import('../../src/components/MultipleWalletPluginsTips.vue' /* webpackChunkName: "components/multiple-wallet-plugins-tips" */).then(c => wrapFunctional(c.default || c))
export const PaymentTokenSelect = () => import('../../src/components/PaymentTokenSelect.vue' /* webpackChunkName: "components/payment-token-select" */).then(c => wrapFunctional(c.default || c))
export const PwBalanceInsufficientDialog = () => import('../../src/components/PwBalanceInsufficientDialog.vue' /* webpackChunkName: "components/pw-balance-insufficient-dialog" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../src/components/Search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const ServiceUpgradeTips = () => import('../../src/components/ServiceUpgradeTips.vue' /* webpackChunkName: "components/service-upgrade-tips" */).then(c => wrapFunctional(c.default || c))
export const Skeleton = () => import('../../src/components/Skeleton.vue' /* webpackChunkName: "components/skeleton" */).then(c => wrapFunctional(c.default || c))
export const Toast = () => import('../../src/components/Toast.vue' /* webpackChunkName: "components/toast" */).then(c => wrapFunctional(c.default || c))
export const WalletConnectButton = () => import('../../src/components/WalletConnectButton.vue' /* webpackChunkName: "components/wallet-connect-button" */).then(c => wrapFunctional(c.default || c))
export const WalletConnectTips = () => import('../../src/components/WalletConnectTips.vue' /* webpackChunkName: "components/wallet-connect-tips" */).then(c => wrapFunctional(c.default || c))
export const WarningAlert = () => import('../../src/components/WarningAlert.vue' /* webpackChunkName: "components/warning-alert" */).then(c => wrapFunctional(c.default || c))
export const Wireframe = () => import('../../src/components/Wireframe.vue' /* webpackChunkName: "components/wireframe" */).then(c => wrapFunctional(c.default || c))
export const FormAccountInput = () => import('../../src/components/form/AccountInput.vue' /* webpackChunkName: "components/form-account-input" */).then(c => wrapFunctional(c.default || c))
export const FormCheckbox = () => import('../../src/components/form/Checkbox.vue' /* webpackChunkName: "components/form-checkbox" */).then(c => wrapFunctional(c.default || c))
export const FormSelect = () => import('../../src/components/form/Select.vue' /* webpackChunkName: "components/form-select" */).then(c => wrapFunctional(c.default || c))
export const FormStripeInput = () => import('../../src/components/form/StripeInput.vue' /* webpackChunkName: "components/form-stripe-input" */).then(c => wrapFunctional(c.default || c))
export const FormTextInput = () => import('../../src/components/form/TextInput.vue' /* webpackChunkName: "components/form-text-input" */).then(c => wrapFunctional(c.default || c))
export const FormTextarea = () => import('../../src/components/form/Textarea.vue' /* webpackChunkName: "components/form-textarea" */).then(c => wrapFunctional(c.default || c))
export const IconImage = () => import('../../src/components/icon/IconImage.vue' /* webpackChunkName: "components/icon-image" */).then(c => wrapFunctional(c.default || c))
export const IconIconfont = () => import('../../src/components/icon/Iconfont.vue' /* webpackChunkName: "components/icon-iconfont" */).then(c => wrapFunctional(c.default || c))
export const NavTopNav = () => import('../../src/components/nav/TopNav.vue' /* webpackChunkName: "components/nav-top-nav" */).then(c => wrapFunctional(c.default || c))
export const NavTopNavMobile = () => import('../../src/components/nav/TopNavMobile.vue' /* webpackChunkName: "components/nav-top-nav-mobile" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
