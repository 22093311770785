import { Context } from '@nuxt/types'
import { loadStripe } from '@stripe/stripe-js'
import { isProdData } from '~~/config'

export default async function (context: Context, inject: Function) {
  const publishableKey = isProdData ? 'pk_live_51MlgcMIQKpnIBBapTkwgXkXXg6tj80bxPUQ2020h9d9Wz8CfxIKMKBl8h7nNkMg7fyrAU8XPgXmMBqXis8Z096Rr00IHDhT1Gc' : 'pk_test_51MlgcMIQKpnIBBapS10WZHylEbWGfk1ZiJ7k9JufVRc8NJEtgpo8y8k0TQqt9VFI5K61Lw3WV6wvjW34xM8FZUas00dAANIaz2'
  const stripe = await loadStripe(publishableKey)
  if (stripe) {
    inject('stripe', stripe)
  }
}
