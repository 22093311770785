const packageJson = require('./package.json')
const isProdData = process.env.IS_PROD === 'true'
const hostname = isProdData ? packageJson.name : 'testdata.did.id'

module.exports = {
  isProdData,
  appName: packageJson.name,
  description: packageJson.description,
  hostname,
  domain: `https://${hostname}/`,
  servicesApi: isProdData ? 'https://register-api.did.id/v1' : 'https://test-register-api.did.id/v1',
  subAccountApi: isProdData ? 'https://subaccount-api.did.id/v1' : 'https://test-subaccount-api.did.id/v1',
  identiconServer: 'https://display.did.id/identicon/',
  appDaSystems: isProdData ? 'https://app.did.id' : 'https://test2f7a872b.did.id',
  dotbitBalance: isProdData ? 'https://balance.did.id' : 'https://testbalance.did.id',
  superDid: isProdData ? 'https://superdid.id' : 'https://testnet.superdid.id',
  ckbNode: isProdData ? 'https://register-api.did.id/v1/node/ckb/rpc' : 'https://test-register-api.did.id/v1/node/ckb/rpc',
  reverseApi: isProdData ? 'https://reverse-api.did.id/v1' : 'https://test-reverse-api.did.id/v1',
  subdidApp: isProdData ? 'https://topdid.com' : 'https://test.topdid.com',
  fiatTermsLink: isProdData ? 'https://topdid.com/terms.html' : 'https://test.topdid.com/terms.html'
}
