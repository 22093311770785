import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5c9c03e2 = () => interopDefault(import('../src/pages/debug/index.vue' /* webpackChunkName: "pages/debug/index" */))
const _a44e260e = () => interopDefault(import('../src/pages/-/AccountStatusCard.vue' /* webpackChunkName: "pages/-/AccountStatusCard" */))
const _6797e035 = () => interopDefault(import('../src/pages/-/ActionButtons.vue' /* webpackChunkName: "pages/-/ActionButtons" */))
const _2d82b5b0 = () => interopDefault(import('../src/pages/-/ActionLabel.vue' /* webpackChunkName: "pages/-/ActionLabel" */))
const _35828d18 = () => interopDefault(import('../src/pages/-/AddNewItem.vue' /* webpackChunkName: "pages/-/AddNewItem" */))
const _4db402a6 = () => interopDefault(import('../src/pages/-/AddParsingRecord.vue' /* webpackChunkName: "pages/-/AddParsingRecord" */))
const _300605d1 = () => interopDefault(import('../src/pages/-/CategoryHeader.vue' /* webpackChunkName: "pages/-/CategoryHeader" */))
const _70c8deee = () => interopDefault(import('../src/pages/-/ConfirmChanges.vue' /* webpackChunkName: "pages/-/ConfirmChanges" */))
const _31e96488 = () => interopDefault(import('../src/pages/-/ConfirmChangesItem.vue' /* webpackChunkName: "pages/-/ConfirmChangesItem" */))
const _f8f24dee = () => interopDefault(import('../src/pages/-/ExitEditModeTips.vue' /* webpackChunkName: "pages/-/ExitEditModeTips" */))
const _04b5e5e6 = () => interopDefault(import('../src/pages/-/HomeSearch.vue' /* webpackChunkName: "pages/-/HomeSearch" */))
const _5f23c71d = () => interopDefault(import('../src/pages/-/ManageButton.vue' /* webpackChunkName: "pages/-/ManageButton" */))
const _0b93220a = () => interopDefault(import('../src/pages/-/ManagePermissionBottomSheet.vue' /* webpackChunkName: "pages/-/ManagePermissionBottomSheet" */))
const _67c1b4d1 = () => interopDefault(import('../src/pages/-/ModifyParsingRecord.vue' /* webpackChunkName: "pages/-/ModifyParsingRecord" */))
const _1c06f2cc = () => interopDefault(import('../src/pages/-/NoItem.vue' /* webpackChunkName: "pages/-/NoItem" */))
const _731b58d8 = () => interopDefault(import('../src/pages/-/NotManagerTips.vue' /* webpackChunkName: "pages/-/NotManagerTips" */))
const _5df6aec4 = () => interopDefault(import('../src/pages/-/NotOwnerTips.vue' /* webpackChunkName: "pages/-/NotOwnerTips" */))
const _3913ae98 = () => interopDefault(import('../src/pages/-/ParsingRecords.vue' /* webpackChunkName: "pages/-/ParsingRecords" */))
const _e9daba8e = () => interopDefault(import('../src/pages/-/Processing.vue' /* webpackChunkName: "pages/-/Processing" */))
const _46e98d7b = () => interopDefault(import('../src/pages/-/RecordDetails.vue' /* webpackChunkName: "pages/-/RecordDetails" */))
const _1cf956fc = () => interopDefault(import('../src/pages/-/RenewalBottomSheet.vue' /* webpackChunkName: "pages/-/RenewalBottomSheet" */))
const _19954a40 = () => interopDefault(import('../src/pages/-/SetCustomScriptTips.vue' /* webpackChunkName: "pages/-/SetCustomScriptTips" */))
const _0cffb9ea = () => interopDefault(import('../src/pages/sub-accounts/-/BackToHome.vue' /* webpackChunkName: "pages/sub-accounts/-/BackToHome" */))
const _75d545b3 = () => interopDefault(import('../src/pages/sub-accounts/-/CreateSubAccount.vue' /* webpackChunkName: "pages/sub-accounts/-/CreateSubAccount" */))
const _1da1612a = () => interopDefault(import('../src/pages/sub-accounts/-/MintSubAccounts.vue' /* webpackChunkName: "pages/sub-accounts/-/MintSubAccounts" */))
const _1afb282d = () => interopDefault(import('../src/pages/sub-accounts/-/SubAccountGains.vue' /* webpackChunkName: "pages/sub-accounts/-/SubAccountGains" */))
const _f580fcc2 = () => interopDefault(import('../src/pages/sub-accounts/-/SubAccountSearch.vue' /* webpackChunkName: "pages/sub-accounts/-/SubAccountSearch" */))
const _e76d22bc = () => interopDefault(import('../src/pages/sub-accounts/enable/_account.vue' /* webpackChunkName: "pages/sub-accounts/enable/_account" */))
const _c5c20b0e = () => interopDefault(import('../src/pages/error/_account.vue' /* webpackChunkName: "pages/error/_account" */))
const _7c02c15a = () => interopDefault(import('../src/pages/not-created/_account.vue' /* webpackChunkName: "pages/not-created/_account" */))
const _318917d7 = () => interopDefault(import('../src/pages/on-cross/_account.vue' /* webpackChunkName: "pages/on-cross/_account" */))
const _09bdc80c = () => interopDefault(import('../src/pages/registerable/_account.vue' /* webpackChunkName: "pages/registerable/_account" */))
const _2f447576 = () => interopDefault(import('../src/pages/sub-accounts/_account.vue' /* webpackChunkName: "pages/sub-accounts/_account" */))
const _5fc3eb9e = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4e3bf6d2 = () => interopDefault(import('../src/pages/_account.vue' /* webpackChunkName: "pages/_account" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/data/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/debug",
    component: _5c9c03e2,
    name: "debug"
  }, {
    path: "/-/AccountStatusCard",
    component: _a44e260e,
    name: "--AccountStatusCard"
  }, {
    path: "/-/ActionButtons",
    component: _6797e035,
    name: "--ActionButtons"
  }, {
    path: "/-/ActionLabel",
    component: _2d82b5b0,
    name: "--ActionLabel"
  }, {
    path: "/-/AddNewItem",
    component: _35828d18,
    name: "--AddNewItem"
  }, {
    path: "/-/AddParsingRecord",
    component: _4db402a6,
    name: "--AddParsingRecord"
  }, {
    path: "/-/CategoryHeader",
    component: _300605d1,
    name: "--CategoryHeader"
  }, {
    path: "/-/ConfirmChanges",
    component: _70c8deee,
    name: "--ConfirmChanges"
  }, {
    path: "/-/ConfirmChangesItem",
    component: _31e96488,
    name: "--ConfirmChangesItem"
  }, {
    path: "/-/ExitEditModeTips",
    component: _f8f24dee,
    name: "--ExitEditModeTips"
  }, {
    path: "/-/HomeSearch",
    component: _04b5e5e6,
    name: "--HomeSearch"
  }, {
    path: "/-/ManageButton",
    component: _5f23c71d,
    name: "--ManageButton"
  }, {
    path: "/-/ManagePermissionBottomSheet",
    component: _0b93220a,
    name: "--ManagePermissionBottomSheet"
  }, {
    path: "/-/ModifyParsingRecord",
    component: _67c1b4d1,
    name: "--ModifyParsingRecord"
  }, {
    path: "/-/NoItem",
    component: _1c06f2cc,
    name: "--NoItem"
  }, {
    path: "/-/NotManagerTips",
    component: _731b58d8,
    name: "--NotManagerTips"
  }, {
    path: "/-/NotOwnerTips",
    component: _5df6aec4,
    name: "--NotOwnerTips"
  }, {
    path: "/-/ParsingRecords",
    component: _3913ae98,
    name: "--ParsingRecords"
  }, {
    path: "/-/Processing",
    component: _e9daba8e,
    name: "--Processing"
  }, {
    path: "/-/RecordDetails",
    component: _46e98d7b,
    name: "--RecordDetails"
  }, {
    path: "/-/RenewalBottomSheet",
    component: _1cf956fc,
    name: "--RenewalBottomSheet"
  }, {
    path: "/-/SetCustomScriptTips",
    component: _19954a40,
    name: "--SetCustomScriptTips"
  }, {
    path: "/sub-accounts/-/BackToHome",
    component: _0cffb9ea,
    name: "sub-accounts---BackToHome"
  }, {
    path: "/sub-accounts/-/CreateSubAccount",
    component: _75d545b3,
    name: "sub-accounts---CreateSubAccount"
  }, {
    path: "/sub-accounts/-/MintSubAccounts",
    component: _1da1612a,
    name: "sub-accounts---MintSubAccounts"
  }, {
    path: "/sub-accounts/-/SubAccountGains",
    component: _1afb282d,
    name: "sub-accounts---SubAccountGains"
  }, {
    path: "/sub-accounts/-/SubAccountSearch",
    component: _f580fcc2,
    name: "sub-accounts---SubAccountSearch"
  }, {
    path: "/sub-accounts/enable/:account?",
    component: _e76d22bc,
    name: "sub-accounts-enable-account"
  }, {
    path: "/error/:account?",
    component: _c5c20b0e,
    name: "error-account"
  }, {
    path: "/not-created/:account?",
    component: _7c02c15a,
    name: "not-created-account"
  }, {
    path: "/on-cross/:account?",
    component: _318917d7,
    name: "on-cross-account"
  }, {
    path: "/registerable/:account?",
    component: _09bdc80c,
    name: "registerable-account"
  }, {
    path: "/sub-accounts/:account?",
    component: _2f447576,
    name: "sub-accounts-account"
  }, {
    path: "/",
    component: _5fc3eb9e,
    name: "index"
  }, {
    path: "/:account",
    component: _4e3bf6d2,
    name: "account"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
