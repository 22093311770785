import { Context } from '@nuxt/types'
import errno from '~/constant/errno'

function isUserReject (err: any) {
  const metaMaskReject = [errno.metaMaskUserRejectedAccountAccess, errno.metaMaskUserDeniedMessageSignature].includes(err.code)
  const tronReject = err === errno.tronLinkConfirmationDeclinedByUser
  const walletConnectReject = err.message === errno.walletConnectUserRejectedTheTransaction
  return metaMaskReject || tronReject || walletConnectReject
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ({ app }: Context, inject: Function) {
  inject('handleError', (err: Error & {code: number}) => {
    if (isUserReject(err)) {
      return
    }
    // wallet
    if (err.code === errno.apiErrorCodeAccountFrequencyLimit) {
      app.$alert({
        title: app.$tt('Tips'),
        message: app.$tt('The operation is too frequent. Please try again after {timeInterval} minutes', { timeInterval: 3 })
      })
    }
    else if (err.code === errno.metaMaskWalletRequestPermissions || err.code === errno.tronLinkAuthorizationRequestsAreBeingProcessed) {
      app.$alert({
        title: app.$tt('Tips'),
        message: app.$tt('Other requests for the wallet are not processed, please try again after processing')
      })
    }
    else if (err.message === errno.coinbaseWalletUsingMultipleWallet) {
      app.$alert({
        title: app.$tt('Tips'),
        message: app.$tt('Please check if you are using multiple wallet plugins. Please disable multiple wallet plugins, keep only one wallet plugin and try again.')
      })
    }
    else {
      app.$alert({
        title: app.$tt('Error'),
        message: err.code ? `${err.code}: ${err.message}` : err
      })
    }
  })
}
