import { Context } from '@nuxt/types'
import { Wallet } from 'wallet-bridge'
import { isProdData } from '~~/config'
import { onGlobalSetup, provide } from '@nuxtjs/composition-api'
import { ME_KEYS } from '~/store/me'

export const WalletSdkProvider = 'WalletSdkProvider'

export default function (context: Context, inject: Function) {
  const _wallet = new Wallet({
    isTestNet: !isProdData,
    loggedInSelectAddress: false,
  })
  void _wallet.initWallet({ involution: false })
  void context.store.dispatch(ME_KEYS.getConnectedAccount)

  _wallet.walletSDK?.context?.addEventListener('walletConnect', () => {
    void context.store.dispatch(ME_KEYS.getConnectedAccount)
    window.location.reload()
  })

  _wallet.walletSDK?.context?.addEventListener('walletDisconnect', () => {
    void context.store.dispatch(ME_KEYS.getConnectedAccount)
  })

  _wallet.walletSDK?.context?.addEventListener('walletChange', () => {
    void context.store.dispatch(ME_KEYS.getConnectedAccount)
    window.location.reload()
  })

  inject('wallets', _wallet)

  // provide services for composition-api
  onGlobalSetup(() => {
    provide<Wallet>(WalletSdkProvider, _wallet)
  })
}
