import Axios from 'axios'
import { BasicService } from '~/services/BasicService'
import { isProdData } from '~~/config'
import { ACCOUNT_STATUS, ACCOUNT_SUFFIX } from '~/constant'
import { splitAccount, validateParams } from '~/modules/tools'

export interface IToken {
  chain_type: number,
  contract: string,
  decimals: number,
  name: string,
  price: string,
  symbol: string,
  token_id: string,
  logo?: string,
}

export interface IConfig {
  sale_cell_capacity: number, // CKB that requires pledge for uploading account
  min_sell_price: number, // minimum price for sale
  offer_cell_capacity: number, // minimum amount of quotation
  premium_percentage: string, // only stripe
  premium_base: string, // only stripe
}

export interface IRegisterTxMap {
  chain_id: number,
  hash: string,
  token_id: string,
}

export interface ISearchAccount {
  status: ACCOUNT_STATUS,
  account: string,
  is_self: boolean,
  account_price: string,
  base_amount: string,
  register_tx_map: { [key: string]: IRegisterTxMap },
}

export interface IUpgradeNoticeRes {
  [key: string]: { [key: string]: string },
}

export default class Common extends BasicService {
  /**
   * get token list
   */
  tokens (): Promise<{ token_list: IToken[] }> {
    return this.axios.post('/token/list')
  }

  /**
   * get config info
   */
  config (): Promise<IConfig> {
    return this.axios.post('/config/info')
  }

  /**
   * search account
   * @param account
   * @param chain_type
   * @param address
   */
  searchAccount ({
    account,
    chain_type,
    address
  }: {
    account: string,
    chain_type?: number,
    address?: string,
  }): Promise<ISearchAccount> {
    validateParams({ account }, 'searchAccount')
    account = account.replace(/\.bit$/, '')
    return this.axios.post('/account/search', {
      account: account + ACCOUNT_SUFFIX,
      chain_type,
      address,
      account_char_str: splitAccount(account, true)
    })
  }

  async upgradeNotice (): Promise<IUpgradeNoticeRes> {
    try {
      const timestamp = Date.now()
      const fileName = isProdData ? 'upgrade-notice' : 'upgrade-notice-test'
      const { data } = await Axios.get(`https://dotbit-upgrade-notice.oss-cn-hongkong.aliyuncs.com/upgrade%20notice/${fileName}.json?t=${timestamp}`)
      if (data && data.end_date) {
        const endTimestamp = new Date(`${data.end_date}T24:00:00.000+0800`).getTime()
        return timestamp < endTimestamp ? data : {}
      }
      return {}
    }
    catch (err) {
      console.error(err)
      return {}
    }
  }
}
