import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { getWalletState, CoinType, WalletProtocol } from 'wallet-bridge'
import { augmentKeys } from '~/modules/tools'
import {
  ChainType,
  CoinTypeToChainIdMap,
  CoinTypeToChainMap,
  EvmCoinTypes,
  IMainChain
} from '~/constant/chain'
import { IReverseInfoRes } from '~/services/DasReverse'

export interface IConnectedAccount {
  address: string,
  chain: IMainChain,
  protocol: WalletProtocol,
  canAddDevice: boolean,
  backedUp: boolean,
  deviceName: string,
  iCloudPasskeySupport: boolean,
}

const keys = {
  namespace: 'me',
  // mutations
  setInviter: 'setInviter',
  setChannel: 'setChannel',
  setConnectedAccount: 'setConnectedAccount',
  setReverseRecord: 'setReverseRecord',
  // actions
  fetchReverseRecord: 'fetchReverseRecord',
  getConnectedAccount: 'getConnectedAccount',
  // getters
  computedChainType: 'computedChainType',
  computedChainId: 'computedChainId'
}

export const state = () => ({
  inviter: '',
  channel: '',
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  connectedAccount: {
    address: ''
  } as IConnectedAccount,
  reverseRecord: {
    account: '',
    is_valid: false
  }
})

export type MeState = ReturnType<typeof state>

export const mutations: MutationTree<MeState> = {
  [keys.setInviter]: (state, inviter: string) => {
    state.inviter = inviter
  },
  [keys.setChannel]: (state, channel: string) => {
    state.channel = channel
  },
  [keys.setConnectedAccount]: (state, connectedAccount: IConnectedAccount) => {
    state.connectedAccount = {
      ...state.connectedAccount,
      ...connectedAccount
    }
  },
  [keys.setReverseRecord]: (state, reverseRecord: IReverseInfoRes) => {
    state.reverseRecord = reverseRecord
  }
}

export const actions: ActionTree<MeState, MeState> = {
  async [keys.fetchReverseRecord] ({ commit, state }) {
    const address = state.connectedAccount.address
    const coinType = state.connectedAccount.chain?.coinType
    if (!address || !coinType) {
      commit(keys.setReverseRecord, {
        account: '',
        is_valid: false
      })
      return
    }
    try {
      const res = await this.$services.dasReverse.reverseInfo({
        coinType: coinType,
        address: address
      })
      if (res) {
        commit(keys.setReverseRecord, res)
      }
      else {
        commit(keys.setReverseRecord, {
          account: '',
          is_valid: false
        })
      }
    }
    catch (err) {
      commit(keys.setReverseRecord, {
        account: '',
        is_valid: false
      })
      console.error(err)
      throw err
    }
  },
  [keys.getConnectedAccount] ({ commit }) {
    const { walletSnap } = getWalletState()
    commit(keys.setConnectedAccount, {
      protocol: walletSnap.protocol,
      address: walletSnap.address,
      chain: walletSnap.coinType && CoinTypeToChainMap[walletSnap.coinType],
      canAddDevice: walletSnap.canAddDevice,
      backedUp: walletSnap.deviceList && walletSnap.deviceList.length > 0,
      deviceName: walletSnap.deviceData?.name,
      iCloudPasskeySupport: walletSnap.iCloudPasskeySupport
    })
  }
}

export const getters: GetterTree<MeState, MeState> = {
  [keys.computedChainType] (state): number | undefined {
    const coinType = state.connectedAccount.chain?.coinType
    let value
    if (EvmCoinTypes.includes(coinType)) {
      value = ChainType.eth
    }
    else if (CoinType.trx === coinType) {
      value = ChainType.tron
    }
    else if (CoinType.doge === coinType) {
      value = ChainType.doge
    }
    else if (CoinType.ckb === coinType) {
      value = ChainType.webauthn
    }
    return value
  },
  [keys.computedChainId] (state): number | undefined {
    const coinType = state.connectedAccount.chain?.coinType
    return CoinTypeToChainIdMap[coinType]
  }
}

export const ME_KEYS = augmentKeys(keys, keys.namespace)
